//=================================================
// Card
//=================================================

.card {
	.card-header {
		padding: $card-spacer-y $card-spacer-x;
		margin-bottom: 0 !important;
		@extend .after;
	}

	.card-meta {
		position: relative;
		margin-bottom: $space_xs - $p_font_mb + 0.211rem;

		.btn {
			color: inherit;

			&.btn-before-horbar {
				&:before {
					color: inherit;
					transition: color 0s ease;
				}
			}

			&:hover {
				color: $body_links_color;
			}

			a {
				color: inherit;
				transition: color 0s ease;
			}
		}
	}

	.card-body {
		padding: $card-spacer-y $card-spacer-x;
	}

	.card-footer {
		padding: $card-spacer-y $card-spacer-x;
	}

	ul {
		margin-bottom: 0 !important;
	}
}

/*=============================
// Card Columns
=============================*/
.card-columns {
	&.columns-4 {
		column-count: 4;
	}

	&.column-no-gap {
		column-gap: 0;
	}
}

/*=============================
// Accordion card
=============================*/
.accordion {
	.card {
		border: 0;

		.card-header {
			position: relative;
			border: 0;
			padding-left: 0;
			padding-right: 0;
			border-bottom: 1px solid $body_border_color;
		}

		.card-body {
			padding-left: 0;
			padding-right: 0;
			padding-bottom: 0;
		}
	}
}
