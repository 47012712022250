//=================================================
// Blog
//=================================================

/*=============================
// Card post
=============================*/
.card-post {
    @extend .style-default;
    background-color: $card_background_color;
    border: 0;
    height: 100%;

    .img {
       min-height: 184px;

        &.object-fit {
            @media #{$media_md} {
                max-height: 184px;
            }

            &:before {
                padding-top: 67%;
            }
        }
    }

    .card-top {
        .post-date {
            position: absolute;
            left: 1.316rem;
            bottom: 1.316rem;
        }
    }

    .card-body {
        padding: $card-spacer-y 0;
        padding-bottom: 0;

        .card-meta {
            p {
                font-size: $f_size_df;

                span {
                    margin-right: 0.368rem;

                    &:last-child {
                        margin-right: 0;
                    }
                }

                a {
                    color: inherit;

                    &:hover {
                        color: $body_links_color;
                    }
                }
            }
        }

        .card-title {
            margin-bottom: $space_xs - $h4_font_mb !important;

            a {
                display: block;
                color: $body_links_color;

                &:hover {
                    color: $body_links_hover_color;
                }
            }
        }
    }

    &.sticky {
        box-shadow: 0 20px 40px -20px rgba(0, 0, 0, 0.09);

        .card-body {
            padding: $card_spacer_x;
        }
    }

    &.no-thumbnail {
        .card-body {
            padding-top: 0;
        }
    }
}

.post-date {
    .post-date-0 {
        position: relative;
        width: 3.421rem;
        height: 3.421rem;
        background-color: $white;

        p {
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));
            font-size: $f_size_sm;
            color: $body_main_element_primary_color;
            text-align: center;
            line-height: normal;

            &:before, &:after {
                display: none;
            }

            .post-date-number {
                display: block;
                font-size: $h5_font_size;
                font-family: $font_primary_bold;
                font-weight: 700;
                line-height: 0.8;
            }
        }
    }
}
